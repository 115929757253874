import React, { Component } from "react";
import DateTime from "react-datetime";
import moment from "moment";
import { animateScroll as scroll } from "react-scroll";

import Select from "react-select";

import Button from "../../components/Button/Button";

class ShopifyProduct extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    console.log(props);
    this.state = {
      shop_product: props.shop_product,
      vendor: props.shop_product.vendor,
      collections: props.shop_product.collections.edges.map(col => {
        return <p key={col.node.id}>{col.node.handle}</p>;
      }),
      variants: props.shop_product.variants.edges.map(variant => {
        return (
          <p key={variant.node.id}>
            {variant.node.title} : {variant.node.inventoryQuantity} [
            {variant.node.price} | {variant.node.compareAtPrice}]
          </p>
        );
      }),
      metafields: props.shop_product.metafields.edges,
      editMetafields: false,
      isLoading: false,
      date: moment().format("YYYY-MM-DDTHH:mm"),
      selectedCategory: null,
      selectedVendor: null
    };
  }

  shop_productChangeHandler = (propertyName, event) => {
    //const { name, value } = event.target;
    //console.log(propertyName);
    //console.log(event.target.value);
    const metafields = this.state.metafields;
    var index = metafields.findIndex(name => name.node.key === propertyName);
    console.log(index);
    metafields[index].node.value = event.target.value;
    this.setState({
      metafields: metafields
    });
  };

  onDateChange = date => {
    const metafields = this.state.metafields;
    var timerIndex = metafields.findIndex(name => name.node.key === "timer");
    metafields[timerIndex].node.value = moment(date).format("YYYY-MM-DDTHH:mm");
    console.log(moment(date).format("YYYY-MM-DDTHH:mm"));
    console.log(metafields);
    this.setState(
      {
        metafields,
        date
      },
      () => console.log(`Selected Date:`, date)
    );
  };

  vendorChangeHandler = selectedVendor => {
    this.setState({ vendor: selectedVendor.value }, () => {
      //console.log(`Vendor selected:`, this.state.selectedVendor);
      console.log(`Vendor selected:`, this.state.vendor);
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.setState({ isLoading: true });
    // Need to loop over inputs and get values for all of them.
    console.log(this.state.metafields);
    fetch(process.env.REACT_APP_TUNNEL_URL + "/api/metafields", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + this.props.token,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        productId: this.props.selectedOption.value,
        metafields: this.state.metafields,
        productVendor: this.state.vendor
      })
    })
      .then(res => {
        if (res.status !== 200) {
          throw new Error("Failed to update metafields.");
        }
        return res.json();
      })
      .then(resData => {
        console.log(resData);
        if (resData.errors) {
          throw new Error("Update metafields failed!");
        }
        this.setState({ isLoading: true });
        this.setState({
          metafields: resData.updatedProductMetafields,
          editMetafields: false
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({ isLoading: false });
      });
  };

  handleCancel = () => {
    this.setState({ editMetafields: false });
  };

  editMetafields = () => {
    this.setState({ editMetafields: true });
    this.setState({ isLoading: false });
    setTimeout(function() {
      scroll.scrollToBottom();
    }, 500);
  };

  render() {
    //console.log(this.state.date);
    const { selectedCategory } = this.state;
    return (
      <div>
        <table className="minimalistBlack">
          <thead>
            <tr>
              <th colSpan="3">Shopify Datas</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Product ID</td>
              <td>{this.state.shop_product.id}</td>
              <td></td>
            </tr>
            <tr>
              <td>Product description</td>
              <td>{this.state.shop_product.description}</td>
              <td></td>
            </tr>
            <tr>
              <td>Product onlineStoreUrl</td>
              <td>{this.state.shop_product.onlineStoreUrl}</td>
              <td></td>
            </tr>
            <tr>
              <td>Product collections</td>
              <td>{this.state.collections}</td>
              <td></td>
            </tr>
            <tr>
              <td>Total Inventory</td>
              <td>{this.state.shop_product.totalInventory}</td>
              <td></td>
            </tr>
            <tr>
              <td>Product variants</td>
              <td>{this.state.variants}</td>
              <td></td>
            </tr>
            <tr>
              <td>Product vendor</td>
              <td>{this.state.vendor}</td>
              <td></td>
            </tr>
            <tr>
              <td>Product metafields</td>
              <td>
                {this.state.metafields.map(meta => (
                  <p key={meta.node.id}>
                    {meta.node.namespace} {meta.node.key} :&nbsp;
                    {meta.node.key === "timer" ? (
                      <span>
                        {moment(meta.node.value).format("YYYY-MM-DDTHH:mm")}
                      </span>
                    ) : (
                      <span> {meta.node.value}</span>
                    )}
                    <span className="small">[{meta.node.valueType}]</span>
                  </p>
                ))}
              </td>
              <td>
                <Button
                  mode="raised"
                  type="submit"
                  onClick={this.editMetafields}
                >
                  Edit
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
        {this.state.editMetafields && (
          <form>
            <h3>Shopify vendor</h3>
            <div>
              {this.state.vendor === "ASPHALTE Prod" && (
                <div className="alert">
                  Attention les stocks vont être mis à zéro.
                </div>
              )}
              <div className="field__group vendor">
                <div className="category__selector">
                  <Select
                    value={selectedCategory}
                    onChange={this.vendorChangeHandler}
                    options={[
                      { value: "ASPHALTE Preco", label: "Preco" },
                      { value: "ASPHALTE Stock", label: "Stock" },
                      { value: "ASPHALTE Teasing", label: "Teasing" },
                      { value: "ASPHALTE Prod", label: "Prod" },
                      { value: "ASPHALTE Dev", label: "Dev" }
                    ]}
                  />
                </div>
                <div className="input flex no-margin">
                  <div>
                    <input
                      id={this.state.shop_product.id}
                      name={this.state.vendor}
                      type="text"
                      readOnly
                      value={this.state.vendor}
                    />
                  </div>
                </div>
              </div>
            </div>

            <h3>Shopify metafields</h3>
            {this.state.metafields.map(meta => (
              <div key={meta.node.id}>
                {(() => {
                  switch (meta.node.key) {
                    case "timer":
                      return (
                        <div className="field__group">
                          <div className="date__selector">
                            <label>
                              <span>{meta.node.namespace} </span>
                              <span>{meta.node.key}</span>
                            </label>
                            <DateTime
                              onChange={this.onDateChange}
                              value={moment(meta.node.value)}
                            />
                          </div>
                          <div className="input flex no-margin">
                            <input
                              id={meta.node.id}
                              name={meta.node.key}
                              type="text"
                              readOnly
                              value={moment(meta.node.value).format(
                                "YYYY-MM-DDTHH:mm"
                              )}
                            />
                          </div>
                        </div>
                      );
                    default:
                      return (
                        <div className="input flex">
                          <div>
                            <label>
                              <span>{meta.node.namespace} </span>
                              <span>{meta.node.key}</span>
                              {meta.node.key === "description" && (
                                <span className="small">
                                  <i>
                                    (entrer les 3 lignes séparées pas un
                                    point-virgule ";")
                                  </i>
                                </span>
                              )}
                              {meta.node.key === "teasing_delivery" && (
                                <span className="small">
                                  <i>
                                    (entrer les lignes séparées pas un
                                    point-virgule ";")
                                  </i>
                                </span>
                              )}
                              {meta.node.key === "slider" && (
                                <span className="small">
                                  <i>(custom | null)</i>
                                </span>
                              )}
                              {meta.node.key === "template" && (
                                <span className="small">
                                  <i>(short | null)</i>
                                </span>
                              )}
                            </label>
                            <input
                              id={meta.node.id}
                              name={meta.node.key}
                              type="text"
                              value={meta.node.value}
                              onChange={this.shop_productChangeHandler.bind(
                                this,
                                meta.node.key
                              )}
                            />
                          </div>
                        </div>
                      );
                  }
                })()}
              </div>
            ))}
            <div className="field__group submit">
              <Button design="danger" mode="flat" onClick={this.handleCancel}>
                Cancel
              </Button>
              <Button mode="raised" type="submit" onClick={this.handleSubmit}>
                {this.state.isLoading ? "Loading" : "Submit"}
              </Button>
            </div>
          </form>
        )}
      </div>
    );
  }
}

export default ShopifyProduct;
